<template>
    <a-card style="background-color: #fff;">
        <div>
            <a-form layout="horizontal">
                <div :class="advanced ? null : 'fold'">
                    <a-row>
                        <a-col :md="8" :sm="24" style="max-width: 400px;">
                            <a-form-item label="仓库名称" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                <a-input v-model="queryData.name" placeholder="请输入仓库名称" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24" style="max-width: 400px;">
                            <a-form-item label="仓库编码" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                <a-input v-model="queryData.code" placeholder="请输入仓库编码" />
                            </a-form-item>
                        </a-col>

                        <!-- <a-col :md="8" :sm="24" style="max-width: 400px;">
                            <a-form-item label="创建时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                <a-range-picker :value="dateValue" :ranges="ranges" @change="dateChange" />
                            </a-form-item>
                        </a-col> -->
                        <a-col :md="8" :sm="24">
                            <a-button @click="reset" style="margin-left: 10px;">重置</a-button>
                            <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                @click="search">查询</a-button>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-button @click="showModal('add')">新增仓库</a-button>
                    </a-row>
                    <div style="margin-top: 10px;font-size: 15px;margin-left: 10px;">显示第 {{ total > 0 ? 1 : 0 }} 至 {{
                    queryData.size > total ? total : queryData.size }} 项结果，共 {{ total }} 项</div>
                </div>
            </a-form>
        </div>
        <div>
            <standard-table :columns="columns" :dataSource="dataSource" :loading="tableLoading" :pagination="false">
                <div slot="status" slot-scope="{text}">
                    <a-tag v-if="text == 1" color="green">启用</a-tag>
                    <a-tag v-if="text == 2" color="red">封存</a-tag>
                </div>
                <div slot="provinceName" slot-scope="{record}">{{ record.provinceName }}{{ record.cityName }}{{
                    record.areaName }}</div>
                <div slot="action" slot-scope="{text,record}">
                    <a @click="updateStatus(record.id,2)" v-if="record.status == 1">封存</a>
                    <a @click="updateStatus(record.id,1)" v-if="record.status == 2">启用</a>
                    <a @click="showModal('edit', record)" style="margin-left: 5px;">编辑</a>
                </div>
            </standard-table>
            <div class="page-box">
                <a-pagination :total="total" :current="queryData.page + 1" :page-size-options="pageSizeOptions"
                    :pageSize="queryData.size" @change="handlePageChange" @showSizeChange="onShowSizeChange"
                    show-size-changer />
            </div>
        </div>

        <FormModal v-if="isModalVisible" :visible="isModalVisible" :confirmLoading="confirmLoading" :form="form"
            :formData="formData" @cancel="handleCancel" @submit="submit" />
    </a-card>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import { selectCitymixins } from '@/mixin/selectCity'
import { publicMixin } from '@/mixin/public';
import FormModal from './components/FormModal.vue'
const columns = [
    { title: '序号', customRender: (text, record, index) => index + 1, align: 'center' },
    { title: '仓库名称', dataIndex: 'name', align: 'center' },
    { title: '仓库编码', dataIndex: 'code', align: 'center' },
    { title: '地区', dataIndex: 'provinceName', align: 'center', scopedSlots: { customRender: 'provinceName' } },
    { title: '详细地址', dataIndex: 'addressDetail', align: 'center' },
    { title: '负责人', dataIndex: 'principalName', align: 'center' },
    { title: '联系电话', dataIndex: 'principalMobile', align: 'center' },
    { title: '状态', dataIndex: 'status', align: 'center', scopedSlots: { customRender: 'status' } },
    { title: '操作', align: 'center', scopedSlots: { customRender: 'action' } },
]

export default {
    name: 'QueryList',
    components: { StandardTable, FormModal },
    mixins: [selectCitymixins, publicMixin],
    data() {
        return {
            moment,
            customerData: [],
            userData: [],
            advanced: true,
            columns: columns,
            dataSource: [],
            selectedRows: [],
            current: 1,
            dateValue: '',
            total: 0,
            // 筛选
            queryData: {
                page: 0,
                size: 10,
            },
            pageSizeOptions: ['10', '30', '40', '50', '100', '500', '1000'],
            tableLoading: false,
            downLoading: false,
            tabCurrent: 1,
            currentPage: 1,
            searchProv: [],
            searchCity: [],
            ranges: {
                '今天': [moment(), moment()],
                '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '当月': [moment().startOf('month'), moment().endOf('month')],
                '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            isModalVisible: false,
            confirmLoading: false,
            formData: {
                name: ''
                // 根据具体需要添加更多字段
            },
            form: this.$form.createForm(this) // Ant Design Vue 的表单对象
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        showModal(type, data) {
            // 根据类型区分是新增还是编辑
            if (type === 'add') {
                this.formData = {
                    "addressSame": 0, //地址详情是否一致
                }
                this.isModalVisible = true; // 打开模态框
            } else {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/warehouse/detail/'+data.id, 'post', {}).then(res => {
                    this.formData = res.data.data;
                    this.isModalVisible = true; // 打开模态框
                })
                
            }
            
        },
        handleCancel() {
            this.isModalVisible = false; // 取消操作
        },
        submit(data) {
            if (data.id) {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/warehouse/update', 'post', data).then(res => {
                    if (res.data.code == 200) {
                        console.log(1231231232)
                        this.getData();
                        this.$message.success('更新成功！')
                        this.isModalVisible = false;
                        this.$set(this.formData, 'salesReturnProvinceCode', this.formData.provinceCode);
                    }
                })
            } else {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/warehouse/save', 'post', data).then(res => {
                    if (res.data.code == 200) {
                        this.getData();
                        this.$message.success('保存成功！')
                        this.isModalVisible = false;
                    }
                })
            }
        },
        updateStatus(id,status) {
            request(process.env.VUE_APP_API_BASE_URL + 'srm/warehouse/updateStatus', 'post', {
                id,status
            }).then(res => {
                if (res.data.code == 200) {
                    this.getData();
                    this.$message.success('操作成功！')
                    this.isModalVisible = false;
                }
            })

        },
        provChange(id) {
            this.city = []
            this.queryData.cityList = []
            if (!id) {
                return false;
            }
            request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
                if (res.data.code == 200) {
                    this.city = res.data.data;
                    this.city.forEach(item => {
                        this.queryData.cityList.push(item.name)
                    })
                } else {
                    this.$message.error(res.data.message);
                }
            })
        },
        cityChange(name) {
            if (name) {
                this.queryData.cityList = [name]
            } else {
                this.queryData.cityList = []
                this.city.forEach(item => {
                    this.queryData.cityList.push(item.name)
                })
            }
        },
        onShowSizeChange(current, pageSize) {
            this.queryData.size = pageSize;
            this.getData()
        },
        handlePageChange(page) {
            this.queryData.page = page - 1;
            this.currentPage = page;
            this.getData()
        },
        hasPermission(permission) {
            return this.userPermissions.includes(permission);
        },
        reset() {
            this.queryData = {
                page: 0,
                size: 10
            }
            this.dateValue = ''
            this.searchCity = [];
            this.searchProv = [];
            this.getData()
        },
        search() {
            this.queryData.page = 0;
            this.getData();
        },
        // 获取列表 
        getData() {
            this.tableLoading = true;
            request(process.env.VUE_APP_API_BASE_URL + 'srm/warehouse/list', 'post', this.queryData).then(res => {
                this.tableLoading = false;
                let data = res.data.data;
                if (res.data.code == 200) {
                    this.dataSource = data.data;
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.total = data.total;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        tabPageChange(page) {
            this.queryData.page = page - 1;
            this.tabCurrent = page;
            this.getData()
        },
        exportTask() {
            this.$message.loading('处理中')
            let params = this.queryData;
            params.remarks = ''
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/cake/exportBrand', 'post', params).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('导出成功！')
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        // 格式化年月日
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            // const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
            return result
        },
    }
}
</script>

<style lang="less" scoped>
.search {
    // margin-bottom: 54px;
}

.fold {
    width: calc(100% - 216px);
    display: inline-block
}

.operator {
    margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
    .fold {
        width: 100%;
    }
}

::v-deep .page-content {
    padding: 0 !important;
}

.cre-btn {
    width: 100%;
    height: 64px;
    line-height: 64px;
    background: #EEECFE;
    border-radius: 4px;
    border: 1px dashed #5542F6;
    cursor: pointer;
    font-size: 16px;
    font-family: PingFangHK-Regular, PingFangHK;
    font-weight: 400;
    color: #5542F6;
}

.btn-box button {
    width: 106px;
    height: 36px;
    margin-left: 10px;
}


.page-box {
    display: flex;
    justify-content: right;
    padding-right: 46px;
    margin-top: 10px;
}
</style>