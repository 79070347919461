<template>
  <a-modal :width="1000" :maskClosable="false" :title="formData.id ? '编辑仓库' : '新增仓库'" :visible="visible"
    @cancel="handleCancel" @ok="handleSubmit" :confirm-loading="confirmLoading">
    <a-form-model ref="ruleForm" :model="formData" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <span class="title-text">仓库信息</span>
      <a-row :gutter="[16, 16]">
        <a-col :span="12">
          <a-form-model-item ref="name" label="仓库名称" prop="name">
            <a-input v-model="formData.name" placeholder="请输入仓库名称" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item ref="code" label="仓库编码" prop="code">
            <a-input v-model="formData.code" placeholder="请输入仓库编码" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <span class="title-text">仓库地区</span>
      <a-row :gutter="[16, 16]">
        <a-col :span="8">
          <a-form-model-item label="省份" prop="provinceCode">
            <a-select allowClear @change="info=>provChange(info,1)" v-model="formData.provinceCode"
              :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
              show-search placeholder="请选择省份" option-label-prop="label">
              <a-select-option v-for="item in prov" :key="item.id" :value="item.id" :label="item.name">{{ item.name
                }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

        <a-col :span="8">
          <a-form-model-item label="市" prop="cityCode">
            <a-select @change="info=>cityChange(info,1)" allowClear placeholder="请选择市" v-model="formData.cityCode"
              :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
              show-search option-label-prop="label">
              <a-select-option v-for="item in city" :key="item.id" :value="item.id" :label="item.name">{{ item.name
                }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="区" prop="areaCode">
            <a-select @change="areaChange" allowClear placeholder="请选择区" v-model="formData.areaCode"
              :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
              show-search option-label-prop="label">
              <a-select-option v-for="item in area" :key="item.id" :value="item.id" :label="item.name">{{ item.name
                }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-form-model-item :label-col="4" ref="addressDetail" label="仓库详细地址" prop="addressDetail">
          <a-input v-model="formData.addressDetail" placeholder="请输入仓库详细地址" />
        </a-form-model-item>
      </a-row>

      <span class="title-text">退货信息</span>
      <a-row :gutter="[16, 16]">
        <a-col :span="12">
          <a-form-model-item ref="salesReturnName" :label-col="6" label="退货联系人" prop="salesReturnName">
            <a-input v-model="formData.salesReturnName" placeholder="请输入退货联系人" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item ref="salesReturnMobile" :label-col="6" label="退货联系电话" prop="salesReturnMobile">
            <a-input v-model="formData.salesReturnMobile" placeholder="请输入退货联系电话" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <div>
        <span class="title-text" style="margin-right: 10px;">退货地址</span>
        <a-checkbox
        :checked="isChecked"
          :disabled="!formData.provinceCode || !formData.cityCode || !formData.areaCode || !formData.addressDetail"
          @change="onSame">与仓库地址一致</a-checkbox>

      </div>
      <a-row :gutter="[16, 16]">
        <a-col :span="8">
          <a-form-model-item label="省份" prop="salesReturnProvinceCode">
            <a-select allowClear @change="info=>provSaleChange(info,1)" v-model="formData.salesReturnProvinceCode"
              :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
              show-search placeholder="请选择省份" option-label-prop="label">
              <a-select-option v-for="item in saleProv" :key="item.id" :value="item.id" :label="item.name">{{ item.name
                }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="市" prop="salesReturnCityCode">
            <a-select  @change="info=>citySaleChange(info,1)" allowClear placeholder="请选择市" v-model="formData.salesReturnCityCode"
              :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
              show-search option-label-prop="label">
              <a-select-option v-for="item in saleCity" :key="item.id" :value="item.id" :label="item.name">{{ item.name
                }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="区" prop="salesReturnAreaCode">
            <a-select  allowClear placeholder="请选择区" v-model="formData.salesReturnAreaCode"
              :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
              show-search option-label-prop="label">
              <a-select-option v-for="item in saleArea" :key="item.id" :value="item.id" :label="item.name">{{ item.name
                }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-form-model-item :label-col="4" ref="salesReturnAddressDetail" label="仓库详细地址" prop="salesReturnAddressDetail">
          <a-input v-model="formData.salesReturnAddressDetail" placeholder="请输入仓库详细地址" />
        </a-form-model-item>
      </a-row>

      <span class="title-text">仓库负责人</span>
      <a-row :gutter="[16, 16]">
        <a-col :span="12">
          <a-form-model-item ref="principalName" label="仓库负责人">
            <a-input v-model="formData.principalName" placeholder="请输入仓库负责人名称" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item :label-col="6" ref="principalMobile" label="仓库负责人电话">
            <a-input v-model="formData.principalMobile" placeholder="请输入仓库负责人电话" />
          </a-form-model-item>
        </a-col>
      </a-row>

    </a-form-model>
  </a-modal>
</template>

<script>
import { request } from '@/utils/request'
import { selectCitymixins } from '@/mixin/selectCity'
import { publicMixin } from '@/mixin/public';
export default {
  mixins: [selectCitymixins, publicMixin],
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    confirmLoading: {
      type: Boolean,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rules: {
        name: [{ required: true, message: '请输入仓库名称', trigger: 'blur' }],
        code: [{ required: true, message: '请输入仓库编码', trigger: 'blur' }],
        provinceCode: [{ required: true, message: '请选择省份', trigger: 'change' }],
        cityCode: [{ required: true, message: '请选择市', trigger: 'change' }],
        areaCode: [{ required: true, message: '请选择区', trigger: 'change' }],
        addressDetail: [{ required: true, message: '请输入仓库详细地址', trigger: 'blur' }],
        salesReturnProvinceCode: [{ required: true, message: '请选择省份', trigger: 'change' }],
        salesReturnCityCode: [{ required: true, message: '请选择市', trigger: 'change' }],
        salesReturnAreaCode: [{ required: true, message: '请选择区', trigger: 'change' }],
        salesReturnAddressDetail: [{ required: true, message: '请输入退货详情地址', trigger: 'blur' }],
        salesReturnName: [{ required: true, message: '请输入退货联系人', trigger: 'blur' }],
        salesReturnMobile: [{ required: true, message: '请输入退货联系电话', trigger: 'blur' }],
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 24 },
      saleProv:[],
      saleCity:[],
      saleArea:[],
      isChecked:false
    }
  },
  mounted() {
    this.getSaleCity();
    if (this.formData.id) {

      //   if(this.formData.addressSame == 1) {
      //   this.isChecked = true
      // } else {
        this.isChecked = false
      // }

      // this.isChecked = this.formData.addressSame == 1;

      this.provChange(this.formData.provinceCode);
      this.cityChange(this.formData.cityCode);
      this.provSaleChange(this.formData.salesReturnProvinceCode)
      this.citySaleChange(this.formData.salesReturnCityCode)
    }
  },
  methods: {
    onSame(e) {
      this.isChecked = e.target.checked; // 更新复选框状态
      if (e.target.checked) {
        // 更新数据，并确保是响应式的
        this.$set(this.formData, 'addressSame', 1);
        this.$set(this.formData, 'salesReturnProvinceCode', this.formData.provinceCode);
        this.$set(this.formData, 'salesReturnCityCode', this.formData.cityCode);
        this.$set(this.formData, 'salesReturnAreaCode', this.formData.areaCode);
        this.$set(this.formData, 'salesReturnAddressDetail', this.formData.addressDetail);
        this.saleCity = this.city;
        this.saleArea = this.area;
      } else {
        this.$set(this.formData, 'addressSame', 0);
      }
    },
    onChange(value) {
      console.log(value);
    },

    handleCancel() {
      // 处理取消操作
      this.$emit('cancel');
    },
    handleSubmit() {
      // 处理表单提交
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          console.log(valid, 'valid')
          this.$emit('submit', this.formData)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getSaleCity() {
      console.log('getSaleCity')
      request(process.env.VUE_APP_API_BASE_URL + 'srm/adcode/list/0', 'get').then(res => {
        console.log(res.data.data,'res')
        if (res.data.code == 200) {
          this.saleProv = res.data.data;
          
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    provSaleChange(id,type) {
      this.saleCity = []
      this.saleArea = []
      if (!id) {
        return false;
      }
      if(type) {
        delete this.formData.salesReturnCityCode
        delete this.formData.salesReturnAreaCode
      }
      request(process.env.VUE_APP_API_BASE_URL + 'srm/adcode/list/' + id, 'get').then(res => {
        if (res.data.code == 200) {
          this.saleCity = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    citySaleChange(id,type) {
      if (!id) {
        return false;
      }
      if(type) {
        delete this.formData.salesReturnAreaCode
      }
      this.saleArea = []
      request(process.env.VUE_APP_API_BASE_URL + 'srm/adcode/list/' + id, 'get').then(res => {
        if (res.data.code == 200) {
          this.saleArea = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    areaChange(id) {
      this.countyCode = id;
    },
  }
};
</script>
<style>
.title-text {
  font-weight: 700;
  font-size: 16px;
  color: #333;
}
</style>
